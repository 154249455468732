import IconSvg from "./IconSvg/IconSvg.vue"
import VImg from "./VImg.vue"
import VShare from "./VShare.vue"
import VSkeleton from "./VSkeleton.vue"
import VSwitch from "./VSwitch.vue"
import VSwiper from "./VSwiper.vue"
import VSwiperTransition from "./VSwiperTransition.vue"
import VNavigation from "./VNavigation.vue"
import GPTLoading from "./GPTLoading.vue"
import { VButton } from "./Button"
import { VInput } from "./Input"

export default [
    IconSvg,
    VImg,
    VShare,
    VSkeleton,
    VSwitch,
    VInput,
    VSwiper,
    VSwiperTransition,
    VNavigation,
    GPTLoading,
    VButton,
]
