<template>
    <div>
        <button @click="shareOnLinkedIn">Share on LinkedIn</button>
        <button @click="shareOnFacebook">Share on Facebook</button>
        <button @click="shareOnInstagram">Share on Instagram</button>
    </div>
</template>

<script>

export default {
    name: 'v-share',
    data() {
        return {
            url: 'https://img.freepik.com', // URL to share
            title: 'Check this out!', // Title for the shared content
            imageUrl: 'https://img.freepik.com/free-photo/painting-mountain-lake-with-mountain-background_188544-9126.jpg?t=st=1717407734~exp=1717411334~hmac=ee71f1c7f7017541da587325bd68f66321ec5aa491d4ea248125fbc1be133241&w=2000', // Image URL to share
            description: 'This is an amazing content to share.', // Description for the shared content
        };
    },
    methods: {
        shareOnLinkedIn() {
            const shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(this.url)}&title=${encodeURIComponent(this.title)}&summary=${encodeURIComponent(this.description)}&source=LinkedIn`;
            window.open(shareUrl, '_blank');
        },
        shareOnFacebook() {
            const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.url)}&quote=${encodeURIComponent(this.description)}&picture=${encodeURIComponent(this.imageUrl)}`;
            window.open(shareUrl, '_blank');
        },
        shareOnInstagram() {
            const url = `https://www.instagram.com/create/story/?media=${encodeURIComponent(this.imageUrl)}&caption=${encodeURIComponent(this.text)}`;
            window.open(url, '_blank');
        },
    },
};

</script>
