import { defineComponent as _defineComponent } from 'vue'
import { mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

import { icons } from './icons'
import { computed, onBeforeMount, PropType } from 'vue'

interface IconDefaultSize {
    width?: string | number
    height?: string | number
}

type NameProp = string


export default /*@__PURE__*/_defineComponent({
  ...{
    name: 'icon-svg'
},
  __name: 'IconSvg',
  props: {
    name: {
        type: String as PropType<NameProp>,
        required: true
    },
    defaultSize: {
        type: Object as PropType<IconDefaultSize>,
        required: false,
        default: () => ({
            width: 24,
            height: 24
        })
    },
    size: {
        type: Object as PropType<IconDefaultSize>,
        required: false,
        default: () => ({})
    },
},
  setup(__props) {

const props = __props



const icon = computed(() => {
    return icons.value[props.name]
})

onBeforeMount(async () => {
    if (icon.value != undefined) return
    try {
        const getSvgUrl = require(`@/shared/assets/svg/${props.name}.svg`)

        const response = await fetch(getSvgUrl)
        const svgString: string = await response.text()

        const el = document.createElement('svg')
        el.innerHTML = svgString
        const svg = el.querySelector('svg') ?? el

        const attributes = {}
        for (const attr of svg.attributes) {
            attributes[attr.name] = attr.value
        }

        if (svg.innerHTML == '' || svg.attributes.length == 0) {
            throw new Error('Empty svg error')
        }

        icons.value[props.name] = {
            svg: svg.innerHTML,
            attributes
        }
    } catch (error) {
        console.log(error)
    }
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _mergeProps({...__props.defaultSize, ...icon.value?.attributes, ...__props.size}, {
    innerHTML: icon.value?.svg
  }), null, 16, _hoisted_1))
}
}

})