<template>
    <label class="search-box !bg-body-color">
        <i class="bx bx-search text-[17px] mr-2.5 bg-body-color !text-sidebar-text-color"></i>
        <input type="text" class="!font-normal bg-body-color !text-color" placeholder="Search...">
    </label>
</template>

<script>

export default {
    name: 'v-input',
}

</script>