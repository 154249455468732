<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        class="animation-analizing"
    >
        <rect
            fill="currentColor"
            :x="animationAnalizingBool ? 11 : 10"
            :y="animationAnalizingBool ? undefined : 10"
            :width="animationAnalizingBool ? 2 : 4"
            :height="animationAnalizingBool ? 24 : 4"
            :rx="animationAnalizingBool ? 1 : 2"
        />
        <rect
            fill="currentColor"

            :y="animationAnalizingBool ? 7 : 2"
            :width="animationAnalizingBool ? 2 : 2"
            :height="animationAnalizingBool ? 10 : 20"
            :rx="animationAnalizingBool ? 1 : 1"
        />
        <rect
            fill="currentColor"

            :x="animationAnalizingBool ? 5 : 5"
            :y="animationAnalizingBool ? 4 : 7"
            :width="animationAnalizingBool ? 3 : 2"
            :height="animationAnalizingBool ? 16 : 10"
            :rx="animationAnalizingBool ? 1.5 : 1"
        />
        <rect
            fill="currentColor"

            :x="animationAnalizingBool ? 22 : 22"
            :y="animationAnalizingBool ? 7 : 2"
            :width="animationAnalizingBool ? 2 : 2"
            :height="animationAnalizingBool ? 10 : 20"
            :rx="animationAnalizingBool ? 1 : 1"
        />
        <rect
            fill="currentColor"

            :x="animationAnalizingBool ? 16 : 17"
            :y="animationAnalizingBool ? 4 : 7"
            :width="animationAnalizingBool ? 3 : 2"
            :height="animationAnalizingBool ? 16 : 10"
            :rx="animationAnalizingBool ? 1.5 : 1"
        />
    </svg>
</template>

<script setup>

/*
    <rect
        v-for="(item, index) in animationAnalizing"
        :key="index"
        :x="item?.x"
        :y="item?.y"
        :width="item?.width"
        :height="item?.height"
        :rx="item?.rx"
        fill="currentColor"
    />
*/

import { reactive, ref, onMounted, onBeforeUnmount, defineOptions } from "vue"

defineOptions({
    name: 'gpt-loading'
})

const startRects = [
    { x: 11, width: 2, height: 24, rx: 1 },
    { y: 7, width: 2, height: 10, rx: 1 },
    { x: 5, y: 4, width: 3, height: 16, rx: 1.5 },
    { x: 22, y: 7, width: 2, height: 10, rx: 1 },
    { x: 16, y: 4, width: 3, height: 16, rx: 1.5 },
];
const animationAnalizing = reactive(ref([...startRects]))
const animationAnalizingBool = ref(false)

let interval;

function updateLoadingIcon() {
    animationAnalizingBool.value = !animationAnalizingBool.value
    animationAnalizing.value = animationAnalizingBool.value
        ? startRects
        : [
            { x: 10, y: 10, width: 4, height: 4, rx: 2 },
            { y: 2, width: 2, height: 20, rx: 1 },
            { x: 5, y: 7, width: 2, height: 10, rx: 1 },
            { x: 22, y: 2, width: 2, height: 20, rx: 1 },
            { x: 17, y: 7, width: 2, height: 10, rx: 1 },
        ];
}

onMounted(() => {
    updateLoadingIcon()
    interval = setInterval(updateLoadingIcon, 600)
});

onBeforeUnmount(() => {
    clearInterval(interval)
});

</script>

<style lang="scss" scoped>

.animation-analizing {
    rect {
        transition: all .6s ease-in-out;
    }
}

</style>