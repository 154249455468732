<template>
    <swiper
        :direction="vertical ? 'vertical' : 'horizontal'"
        :easing="easing"
        :speed="Number(speed)"
        :loop="loop"
        :autoplay="autoplay"
        :modules="modules"
        :slidesPerView="slidesPerView"
        :space-between="gap"
        :pagination="{
            dynamicBullets: true,
        }"
        @swiper="onSwiper"
    >
        <!-- @slideChange="onSlideChange" -->
        <slot></slot>
    </swiper>
  </template>
<script>
import { reactive, ref } from "vue"
import { Autoplay, Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules'
// Import Swiper Vue.js components
import { Swiper } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';

// SwiperCore.use([/* Swiper modules you need */]);

export default {
    name: 'v-swiper',
    emits: ['on-swiper'],
    components: {
        Swiper,
    },
    props: {
        gap: {
            type: [String, Number],
            default: 0
        },
        vertical: {
            type: Boolean,
            default: false
        },
        slidesPerView: {
            type: [String, Number],
            default: 'auto',
        },
        easing: {
            type: String,
            default: 'ease-in-out'
        },
        speed: {
            type: [String, Number],
            default: 300
        },
        loop: {
            type: Boolean,
            default: false
        },
        slideIndex: {
            type: Number,
            default: 0
        },
        autoplay: {
            type: Object,
            default: undefined
        },
    },
    methods: {
        onSwiper(swiper) {
            if (this.vertical) {
                this.loadedSwiper = swiper;
            } else {
                console.log('onSwiper(swiper');
                this.$emit('on-swiper', swiper);
            }
        },
    },
    data() {
        if (this.vertical != true) return {};

        return {
            loadedSwiper: {},
        }
    },
    watch: {
        slideIndex(newIndex) {
            console.log('newIndex', newIndex);
            if (this.loadedSwiper) this.loadedSwiper?.slideTo?.(newIndex)
        }
    },
    setup() {
        const lastPaginatinoClasses = reactive(ref(''))
        // const onSwiper = (swiper) => {
        //     console.log(swiper?.slideTo(1));
        // };
        // const onSlideChange = (event) => {
        //     console.log('slide change', event);
        // };
        return {
            // onSwiper,
            // onSlideChange,
            lastPaginatinoClasses,
            modules: [Autoplay, Navigation, Pagination, Scrollbar, A11y]
        };
    },
};
</script>

<style lang="scss">

.swiper {
    // width: 100%;
    // height: 100%;
}
.swiper-slide {

}

</style>