import { createApp } from 'vue'
import App from './App.vue'
import router from './app/router'
import directives from './app/directives'
import UIComponents from './shared/ui'
import './shared/assets/tailwind.scss'
import i18n from "./translate"
import './shared/lib/Prototypes'
import VueSocialSharing from 'vue-social-sharing'

import { SwiperSlide } from 'swiper/vue';

const app = createApp(App)

UIComponents.forEach(UIComponent => {
    app.component(UIComponent.name, UIComponent);
});
app.component('v-slide', SwiperSlide);

directives.forEach(directive => {
    app.directive(directive.name, directive);
});

// @ts-ignore
// Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans)
app.use(VueSocialSharing);
app.use(router)
app.use(i18n)
app.mount('#app')
